var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6L-CndpA5MJOuwqFG7TqG"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import packageJson from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const ignoreErrors = [/otBannerSdk.js$/];
const denyUrls = ['https://cdn.cookielaw.org', 'https://cdn.segment.com', /otBannerSdk.js$/, /extensions\//i, /^chrome:\/\//i];

const initializeSentry = () => {
  if (['development', 'production'].includes(process.env.BUILD_ENV)) {
    Sentry.init({
      environment: process.env.BUILD_ENV,
      denyUrls,
      dsn: SENTRY_DSN || 'https://6f3edf9723114575b0bb8fa183583473@o1055486.ingest.sentry.io/6045377',
      ignoreErrors,
      integrations: [new Integrations.BrowserTracing()],
      normalizeDepth: 10,
      release: `${packageJson.name}@${packageJson.version}`,
      tracesSampleRate: 1.0,
    });
  }
};

initializeSentry();

module.exports = initializeSentry;
